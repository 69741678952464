import React from "react";
import styled from "styled-components";
import {ActionType} from "../../query/loadActions";
import moment from "moment";

const FromToWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

interface Props {
    action: ActionType
}

export default function FromNow(props: Props){
    const {action} = props;
    const fromNow = moment(action.createdAt, 'X').fromNow(true)

    return (
        <>{fromNow}</>
    )
}