import React from 'react';
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteOutlineIcon from '@mui/icons-material/FavoriteBorder';
import {useMutation} from "@apollo/client";
import likeAction from "../../../query/likeAction";
import {useNavigate} from "react-router-dom";
import {useUserProfile} from "../../../hooks/useUserProfile";
import {useSnackbar} from "notistack";

type Props = {
    id: string
    liked: boolean
}

export default function LikeButton(props: Props) {
    const {id,  liked} = props;
    const navigate = useNavigate();
    const {session} = useUserProfile();
    const { enqueueSnackbar } = useSnackbar();

    const [like] = useMutation(likeAction, {
        variables: {
            id: id,
            liked: !liked
        }
    });

    const clickLike = () => {
        if(!session?.user){
            navigate('/profile/');
        }

        like().then(() => {
            if(!liked){
                enqueueSnackbar("Akce byla označena jako oblíbená", {
                    variant: "success",
                    preventDuplicate: true
                });
            }
        });
    }
    const color = liked? "error":"default";
    return (
        <IconButton onClick={clickLike} aria-label={"like a action"} size={"large"} color={color}>
            {liked ? <FavoriteIcon/> : <FavoriteOutlineIcon/>}
        </IconButton>
    );
}