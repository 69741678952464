import {CircularProgress, Grid, IconButton} from "@mui/material";
import ListIcon from '@mui/icons-material/List';
import GridViewIcon from '@mui/icons-material/GridView';
import React, {useEffect, useState} from "react";
import loadActions, {ActionQueryResult, ActionType} from "../../query/loadActions";
import {useQuery} from "@apollo/client";
import {useSearchParams} from "react-router-dom";
import List from "./Version1/List";
import List2 from "./Version2/List";
import styled from "styled-components";

const ViewChangeBar = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    padding-right: 10px;
`

type Props = {
    filterId?: string
    toCountryCode?: string
    filterLiked?: boolean
}

export default function ActionListWrapper (props: Props) {
    const [listType, setListType] = useState<string>(() => {
        return localStorage.getItem('listType') || 'list2';
    });

    useEffect(() => {
        localStorage.setItem('listType', listType);
    }, [listType]);

    const convertToIntegerOrNull = (value: string|null) => {
        if(value === null){
            return null;
        }
        return parseInt(value);
    }
    const getQuickFilter = () => {
        return {
            priceFrom: convertToIntegerOrNull(filterData.get('priceFrom')),
            priceTo: convertToIntegerOrNull(filterData.get('priceTo')),
            priceCurrency: filterData.get('priceCurrency')??"czk",
            fromCountry: filterData.get('fromCountry')??null,
            toCountry: filterData.get('toCountry')??null,
            fromAirport: filterData.get('fromAirport')??null,
            toAirport: filterData.get('toAirport')??null,
            departureDateFrom: convertToIntegerOrNull(filterData.get('departureDateFrom')),
            departureDateTo: convertToIntegerOrNull(filterData.get('departureDateTo')),
            returnDateFrom: convertToIntegerOrNull(filterData.get('returnDateFrom')),
            returnDateTo: convertToIntegerOrNull(filterData.get('returnDateTo')),
        }
    }
    const {filterId, filterLiked} = props;
    const [filterData] = useSearchParams();
    const {data, fetchMore} = useQuery<ActionQueryResult>(loadActions, {
        fetchPolicy: 'cache-and-network',
        variables: {
            filterId: filterId,
            liked: filterLiked,
            quickFilter: getQuickFilter()
        }
    });

    const loadingComponent = function(){
        return <Grid container spacing={2} padding={2} paddingBottom={10} id={"salam"} alignItems={"center"} justifyContent={"space-around"}>
            <CircularProgress />
        </Grid>;
    }

    if(typeof data === 'undefined'){
        return loadingComponent();
    }

    const actions = data.actions;

    if(actions.length === 0){
        return <Grid container spacing={2} padding={2} paddingBottom={10} id={"salam"} alignItems={"center"} justifyContent={"space-around"}>
            No actions found
        </Grid>;
    }

    const lastItem: ActionType = actions.slice(-1)[0]

    const fetchMoreActions = () => fetchMore({
        variables:{
            time: lastItem.createdAt
        }
    })

    const selectListType = (type: string) => {
        setListType(type);
    }

    let list = listType === 'list1' ? <List actions={actions} fetchMoreActions={fetchMoreActions} /> : <List2 actions={actions} fetchMoreActions={fetchMoreActions} />;

    return (
        <>
            <ViewChangeBar>
                <IconButton onClick={() => selectListType('list1')} aria-label="list view"
                            color={listType === 'list1' ? 'primary' : 'default'}>
                    <GridViewIcon/>
                </IconButton>
                <IconButton onClick={() => selectListType('list2')} aria-label="grid view"
                            color={listType === 'list2' ? 'primary' : 'default'}>
                    <ListIcon/>
                </IconButton>
            </ViewChangeBar>
            {list}
        </>
    );
}