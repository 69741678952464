import {ActionType} from "../../../query/loadActions";
import {
    List as MuiList,
    Card,
    CardContent,
    Stack, Grid, CardActions, IconButtonProps, Collapse
} from "@mui/material";
import Typography from "@mui/material/Typography";
import TriggerWhenInViewport from "../../Common/TriggerWhenInViewport";
import React from "react";
import Country from "../../Common/Country";
import Price from "../../Common/Price";
import FromNow from "../../Common/FromNow";
import styled from "styled-components";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LikeButton from "../../UI/Molecules/LikeButton";
import LaunchIcon from '@mui/icons-material/Launch';
import DateRange from "../../Common/DateRange";
import Airport from "../../Common/Airport";

const StyledCard = styled(Card)`
    cursor: pointer;
    margin: 5px;
`

const StyledCardContent = styled(CardContent)`
    :hover {
        background-color: #1ba792;
    }
`

interface Props {
    actions: ActionType[];
    fetchMoreActions: () => void
}

interface ActionProps {
    action: ActionType;
}

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',

}));

function Action(props: ActionProps){
    const {action} = props;

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const openAction = () => {
        window.open(action.url, '_blank')
    };

    return (
        <StyledCard>
                <StyledCardContent sx={{padding: 1}}>
                    <Grid container spacing={2}>
                        <Grid item md={2} xs={3} >
                            <Typography sx={{paddingBottom: 1}} align={"right"}><Price price={action.price} priceCurrency={action.priceCurrency}/></Typography>
                            <Typography align={"right"}><FromNow action={action}/></Typography>
                        </Grid>
                        <Grid item md={10} xs={9}>
                            <Typography sx={{paddingBottom: 1}}>
                                {action.originalTitle}
                            </Typography>
                            <Stack direction="row" sx={{color: "#eb9555"}} spacing={1}>
                                <span>{"Z"} </span><span><Country countryCode={action.fromCountry}/></span><span>{"  do "}</span><strong><Country countryCode={action.toCountry}/></strong>
                            </Stack>
                        </Grid>
                    </Grid>
                </StyledCardContent>
                <CardActions disableSpacing sx={{justifyContent:"flex-end"}}>
                    <LikeButton id={action.id} liked={action.isLiked}/>
                    {/*<IconButton aria-label="share">
                        <ShareIcon />
                    </IconButton>*/}
                    <IconButton aria-label="share" onClick={openAction}>
                        <LaunchIcon />
                    </IconButton>
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>
                </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography variant={"caption"}>Letiště</Typography>
                    <Typography><Airport airportCode={action.fromAirport}/>
                        &rarr;<Airport airportCode={action.toAirport}/></Typography>
                    <Typography variant={"caption"}>Letecká Spolěcnost</Typography>
                    <Typography>{action.carrier}</Typography>
                    <Typography variant={"caption"}>Datum Výletu</Typography>
                    <DateRange action={action}/>


                    <Typography variant={"caption"}>Shrnutí</Typography>
                    <Typography>{action.text}</Typography>
                </CardContent>
            </Collapse>
            </StyledCard>
    );
}

export default function List (props: Props) {
    const {actions, fetchMoreActions} = props;
    return (
        <Container disableGutters={true}>
        <MuiList>
            {actions.map((action, index) => (
                <Action action={action} />
            ))}
            <TriggerWhenInViewport onInViewport={fetchMoreActions} />
        </MuiList>
        </Container>
    )
}