import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline, Switch, FormControlLabel } from "@mui/material";
import { Outlet } from "react-router-dom";
import BottomNav from "./BottomNav";

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: ${props => props.theme.primary1};
`;

export default function Root() {
    const [darkMode, setDarkMode] = useState(true);

    const theme = createTheme({
        palette: {
            mode: darkMode ? 'dark' : 'light',
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <ContentWrapper>
                <Outlet />
            </ContentWrapper>
            <BottomNav darkMode={darkMode} setDarkMode={setDarkMode} />
        </ThemeProvider>
    );
}