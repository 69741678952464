import {Grid} from "@mui/material";
import TriggerWhenInViewport from "../../Common/TriggerWhenInViewport";
import React from "react";
import {ActionType} from "../../../query/loadActions";
import {Action} from "./Action";

interface Props {
    actions: ActionType[];
    fetchMoreActions: () => void
}

export default function List(props: Props){
    const {actions, fetchMoreActions} = props;
    const renderActions = () => {
        return actions.map((item, key) => {
            let lastItem = null;
            if(key > 0){
                lastItem = actions[key-1];
            }
            return <Action key={item.id} action={item} itemBefore={lastItem}/>;
        })
    }

    return (
        <>
            <Grid container spacing={2} padding={2} paddingBottom={10}>
                {renderActions()}
                <TriggerWhenInViewport onInViewport={fetchMoreActions} />
            </Grid>
        </>
    )
}