import React from "react";

export default function Price(props: {
    priceCurrency: string,
    price: number
}) {
    const { priceCurrency, price } = props;
    try {
        const priceFormat = new Intl.NumberFormat('cs-CZ', {
            style: "currency", currency: priceCurrency, minimumFractionDigits: 0
        });
        const divProps = (({price, priceCurrency, ...o}) => o)(props);
        return <span {...divProps}>{price ? priceFormat.format(price) : ''}</span>
    }catch (e) {
        console.error(e)
        return <span>{price ? priceCurrency + " " + price : ''}</span>
    }


}