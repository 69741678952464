import Typography from "@mui/material/Typography";
import {formatDate} from "../../locale/localeHelper";
import React from "react";
import styled from "styled-components";
import {ActionType} from "../../query/loadActions";

const FromToWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

interface Props {
    action: ActionType
}

export default function DateRange(props: Props){
        const {action} = props;
        let dateFrom = new Date(action.dateFrom * 1e3);
        let dateTo = new Date(action.dateTo * 1e3);

        return (
            <FromToWrapper>
                <Typography
                    variant={"caption"}
                >Od: <strong>{formatDate(dateFrom)}</strong></Typography>
                <Typography
                    variant={"caption"}
                >Do: <strong>{formatDate(dateTo)}</strong></Typography>
            </FromToWrapper>
        )
}