import React, {useEffect, useState} from 'react';

interface Props {
    children: React.ReactNode
}

export interface AirportType {
    code: string;
    name: string;
    countryCode: string;
    city: string;
}

export interface CountryType {
    code: string;
    name: string;
}

interface Constants {
    airports: AirportType[]
    countries: AirportType[]
}
export const ConstantsContext = React.createContext<Constants>({
    airports: [],
    countries: []
});


export const ConstantsProvider = (props: Props) => {
    const {children} = props;
    const [data, setData] = useState({airports: [], countries: []});
    const [loading, setLoading] = useState(true);

    const loadConstants = async () => {
        const aiports = await fetch('https://api.coolletenky.cz/constants/airports.json')
            .then(function(response) {
                return response.json();
            });
        const countries = await fetch('https://api.coolletenky.cz/constants/cs/countries.json')
            .then(function(response) {
                return response.json();
            });
        setData({airports: aiports, countries: countries});
        setLoading(false);
    }

    useEffect(() => {
        loadConstants();
    }, []);

    return <ConstantsContext.Provider value={data}>{loading ? null : children}</ConstantsContext.Provider>
}
