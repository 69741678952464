import { Card, CardContent, CardMedia, Divider, Grid } from "@mui/material";
import { ActionType } from "../../../query/loadActions";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import Country from "../../Common/Country";
import styled from "styled-components";
import {default as NotStyledPrice} from "../../Common/Price";
import Button from "@mui/material/Button";
import {formatDate, formatDateTime} from "../../../locale/localeHelper";
import Airport from "../../Common/Airport";
import DateRange from "../../Common/DateRange";
import LikeButton from "../../UI/Molecules/LikeButton";

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const Price =  styled(NotStyledPrice)`
    font-size: 1.4rem;
    color: white;
    font-weight: 500;
    margin: 0;
    padding: 0;
`


const TopRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`

const CreatedAt = styled.div`
    background: rgba(0, 0, 0, 0.5);
    padding: 2px 8px;
    border-radius: 4px;
    color: white;
    font-size: 0.8rem;
    margin: 8px;
`

const PriceDateWrapper = styled.div`
    min-width: 160px;
    text-align: right;
    color: white;
    padding: 20px;
`

const BottomRow = styled(Button)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-transform: none;
    background: rgba(0, 0, 0, 0.5);
    
    :hover {
      background: rgba(0, 0, 0, 0.6);
    }
`

const FirstRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

const ActionBox = styled.div`
    border-radius: 4px;
    color: white;
    font-size: 1rem;
`

type Props = {
    action: ActionType
    itemBefore: null | ActionType
}

export function Action(props: Props) {
    const { action, itemBefore } = props;
    const [imageUrl, setImageUrl] = useState(() => {
        if (action.toCountry == null) {
            return "/country-images/default.jpg";
        }
        return "/country-images/" + action.toCountry.toLowerCase() + ".jpg"
    })
    const [displayContent, setDisplayContent] = useState(false);

    const date = new Date(action.createdAt * 1e3);
    let divider = null;
    if (itemBefore !== null) {
        const lastDate = new Date(itemBefore.createdAt * 1e3);
        if (date.getDay() !== lastDate.getDay()) {
            divider = (
                <Grid item xs={12}>
                    <Divider>{formatDate(date)}</Divider>
                </Grid>
            );
        }
    }


    const cardContent = () => {
        if(!displayContent){
            return null;
        }
        return (
            <CardContent onClick={() => {
                window.open(action.url, '_blank');
            }}>
                <Grid container>
                    <Typography variant="body1">
                        {action.text}
                    </Typography>
                    <Button variant={"contained"} sx={{ textAlign:"right", marginTop: 1 }}>Na web akce</Button>
                </Grid>
            </CardContent>
        )
    }

    return (
        <>
            {divider}
            <Grid item xl={4} md={6} xs={12}>
                <Card>
                    <div style={{ position: "relative" }}>
                         <CardMedia
                            component="img"

                            image={imageUrl}
                            alt="Default Image"
                            onError={() => setImageUrl("/country-images/default.jpg") }
                        />
                        <ImageOverlay>
                            <TopRow>
                                <CreatedAt title={"Vytvořeno"}>
                                    {formatDateTime(new Date(action.createdAt * 1e3))}
                                </CreatedAt>
                                <LikeButton id={action.id} liked={action.isLiked}/>
                            </TopRow>
                            <BottomRow onClick={() => setDisplayContent(!displayContent)}>
                                <FirstRow>
                                    <div>
                                    <Typography
                                        color={"white"}
                                        padding={2}
                                        variant={"h5"}
                                        textAlign={"left"}
                                    >
                                        <Country countryCode={action.toCountry}/>

                                    </Typography>
                                        <Typography
                                            color={"white"}
                                            padding={2}
                                            paddingTop={0}
                                            variant={"subtitle2"}
                                            textAlign={"left"}
                                        >
                                            <Airport airportCode={action.fromAirport}/>
                                            &rarr;<Airport airportCode={action.toAirport}/>
                                        </Typography>

                                    </div>
                                    <PriceDateWrapper>
                                        <Price price={action.price} priceCurrency={action.priceCurrency}/>
                                        <DateRange action={action}/>
                                    </PriceDateWrapper>
                                </FirstRow>
                                <div>
                                    <ActionBox>...</ActionBox>
                                </div>
                            </BottomRow>
                        </ImageOverlay>
                    </div>
                    {cardContent()}
                </Card>
            </Grid>
        </>
    )
}