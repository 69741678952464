import {useContext} from "react";
import {ConstantsContext} from "../../provider/ConstantsProvider";

type Props = {
    countryCode: string
}

export function getCountryName(countryCode: string) {

}

export default function Country (props: Props) {
    const { countryCode } = props;
    const { countries } = useContext(ConstantsContext);
    //check if this code exists in the list of countries
    const country = countries.find(country => country.code === countryCode);

    if(!country) return <span>{countryCode}</span>
    return <>{country.name}</>
}