import React from "react";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FeedIcon from '@mui/icons-material/Feed';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from "@mui/material";

interface BottomNavProps {
    darkMode?: boolean;
    setDarkMode?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function BottomNav({ darkMode, setDarkMode }: BottomNavProps) {
    const location = useLocation();

    const theme = createTheme({
        palette: {
            mode: darkMode ? 'dark' : 'light',
        },
    });

    const handleThemeChange = () => {
        if (setDarkMode) {
            setDarkMode(!darkMode);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1300 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={location.pathname}
                >
                    <BottomNavigationAction component={Link} to={"/"} label="Nejnovější" value={"/"} icon={<HomeIcon />} />
                    <BottomNavigationAction component={Link} to={"/favorites"} label="Oblíbené" value={"/favorites"} icon={<FavoriteIcon />} />
                    <BottomNavigationAction component={Link} to={"/articles"} label="Informace" value={"/articles"} icon={<FeedIcon />} />
                    {darkMode !== undefined && setDarkMode && (
                        <BottomNavigationAction
                            label={darkMode ? 'Light Mode' : 'Dark Mode'}
                            icon={darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                            onClick={handleThemeChange}
                        />
                    )}
                </BottomNavigation>
            </Paper>
        </ThemeProvider>
    );
}